var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"group-setting-administrator"},[_c('div',{staticClass:"group-setting-administrator-header-box"},[_c('div',{staticClass:"administrator-header-left"},[(this.isDeleGroupAdmin)?_c('span',{staticClass:"setting-click-button",on:{"click":function($event){return _vm.changeDeleGroupAdminShow(false,'no')}}},[_vm._v(" "+_vm._s(_vm.$getStringObj.getString(_vm.$Strings.Mind_Group_Cancel))+" ")]):_vm._e()]),_c('div',{staticClass:"administrator-header-main"},[_vm._v(" "+_vm._s(_vm.$getStringObj.getString(_vm.$Strings.Mind_Group_Administrator))+" ")]),_c('div',{staticClass:"administrator-header-right"},[(this.isDeleGroupAdmin)?_c('span',{staticClass:"setting-click-button",on:{"click":function($event){return _vm.changeDeleGroupAdminShow(false,'ok')}}},[_vm._v(" "+_vm._s(_vm.$getStringObj.getString(_vm.$Strings.Mind_Group_Accomplish))+" ")]):_c('span',{staticClass:"setting-click-button",on:{"click":function($event){return _vm.changeDeleGroupAdminShow(true,'update')}}},[_vm._v(" "+_vm._s(_vm.$getStringObj.getString(_vm.$Strings.Mind_Group_Compile))+" ")])])]),(_vm.groupDataDetail != null)?_c('div',{staticClass:"group-admin-list-content"},[_c('div',{staticClass:"group-admin-list-content-children"},[_c('div',{staticClass:"admin-title"},[_vm._v(_vm._s(_vm.$getStringObj.getString(_vm.$Strings.Mind_Group_Compile))+" 群主")]),_c('div',{staticClass:"group-admin-list-box"},_vm._l((_vm.groupAdminList),function(item,index){return _c('div',{key:index},[(item.type == 2)?_c('div',{staticClass:"group-admin-list-each"},[_c('div',{style:({position: 'relative', height: 44 + 'px', width: 44 + 'px', margin: '0 8px 0 0', })},[(item.isMember)?_c('img',{style:({
                            position: 'absolute',
                            zIndex: 1,
                            left: 50 + '%',
                            transform: 'translate(-50%,0)',
                            top: -13 + 'px',
                            width: 24 + 'px',
                            height: 16 + 'px',
                        }),attrs:{"src":require("../../../../assets/img/groupImg/crown.svg"),"alt":""}}):_vm._e(),_c('div',{style:({
                                position: 'absolute',
                                left: '50%',
                                bottom: '1px',
                                transform: 'translate(-50%,0)',
                                width: 44 + 'px',
                                height: 44 + 'px',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center center',
                                overflow: 'hidden',
                                borderRadius: '50%',
                                backgroundImage: 'url('+ _vm.httpImageToPrefix(item.avatar) +')',
                            })})]),_c('div',{staticClass:"admin-name"},[_vm._v(" "+_vm._s(item.username)+" ")])]):_vm._e()])}),0)]),_c('div',{staticClass:"group-admin-list-content-children"},[_c('div',{staticClass:"admin-title"},[_vm._v(_vm._s(_vm.$getStringObj.getString(_vm.$Strings.Mind_Group_Administrator)))]),_c('div',{staticClass:"group-admin-list-box"},_vm._l((_vm.groupAdminList),function(item,index){return _c('div',{key:index},[(item.type != 2 && !item.isDelet)?_c('div',{staticClass:"group-admin-list-each member-pointer",on:{"click":function($event){return _vm.checkItem(item,index)}}},[(_vm.isDeleGroupAdmin )?_c('img',{staticClass:"admin-delet",attrs:{"src":require("../../../../assets/img/groupImg/setting-delet-admin.png"),"alt":""}}):_vm._e(),_c('div',{staticClass:"group-admin-cover",style:({
                          display: 'block',
                          width: 44 + 'px',
                          height: 44 + 'px',
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'cover',
                          backgroundPosition: 'center center',
                          overflow: 'hidden',
                          backgroundImage: 'url('+ _vm.httpImageToPrefix(item.avatar) +')',
                      })}),_c('div',{staticClass:"admin-name"},[_vm._v(" "+_vm._s(item.username)+" ")])]):_vm._e()])}),0)]),_c('div',{staticClass:"group-add-admin-big-box"},[_c('img',{attrs:{"src":require("../../../../assets/img/groupImg/setting-add-admin.png"),"alt":""},on:{"click":function($event){return _vm.addAddmin()}}}),_c('div',{staticClass:"group-add-text",on:{"click":function($event){return _vm.addAddmin()}}},[_vm._v(" "+_vm._s(_vm.$getStringObj.getString(_vm.$Strings.Mind_Group_Add_As_Administrator))+" ")])])]):_vm._e(),_c('GroupAddAdmin',{attrs:{"show":_vm.showModal,"groupDataDetail":_vm.groupDataDetail},on:{"hidden":_vm.hidden}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }