var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('div',{staticClass:"group-comment"},[(_vm.CommentData.gcomments)?_c('div',{staticClass:"storm-user-comment",style:({ height: _vm.fullHeight - 290 + 'px' })},_vm._l((_vm.CommentData.gcomments),function(item,index){return _c('div',{key:index,staticClass:"storm-user-comment-message"},[_c('div',{staticClass:"storm-user-comment-box"},[_c('div',{staticClass:"storm-user-header"},[(item.isMember)?_c('div',{staticClass:"storm-user-img-ismember"},[_c('img',{attrs:{"src":_vm.httpImageToPrefix(item.userAvatar),"alt":""}})]):_c('div',{staticClass:"storm-user-img"},[_c('img',{attrs:{"src":_vm.httpImageToPrefix(item.userAvatar),"onerror":"this.src='/static/img/userData/avatar_yellow_man.png'","alt":""}})])]),_c('div',{staticClass:"storm-comment-content"},[_c('div',{staticClass:"comment-content-header"},[_c('div',{staticClass:"comment-user-name"},[_c('span',[_vm._v(_vm._s(item.username))])]),(false)?_c('div',{staticClass:"comment-cotnent-detials"},[_vm._m(0,true),_vm._m(1,true)]):_vm._e()]),_c('div',{staticClass:"comment-content-reply"},[_c('div',{staticClass:"comment-time"},[_vm._v(" "+_vm._s(_vm.formatTime(item.createTime))+" ")])]),_c('div',{staticClass:"comment-content-box"},[_c('div',{staticClass:"comment-content-text"},[_c('span',[_vm._v(_vm._s(item.content))])])])])]),(_vm.CommentData.gcomments[index].topComments)?_c('div',{staticClass:"storm-comment-reply"},_vm._l((_vm.CommentData.gcomments[index]
              .topComments),function(itemReply,replyIndex){return _c('div',{key:replyIndex,staticClass:"storm-user-comment-message-reply"},[_c('div',{staticClass:"storm-user-header"},[(itemReply.isMember)?_c('div',{staticClass:"storm-user-img-ismember"},[_c('img',{attrs:{"src":_vm.httpImageToPrefix(itemReply.userAvatar),"alt":""}})]):_c('div',{staticClass:"storm-user-img"},[_c('img',{attrs:{"src":_vm.httpImageToPrefix(itemReply.userAvatar),"onerror":"this.src='/static/img/userData/avatar_yellow_man.png'","alt":""}})])]),_c('div',{staticClass:"storm-comment-content"},[_c('div',{staticClass:"comment-content-header"},[_c('div',{staticClass:"comment-user-name"},[_c('span',[_vm._v(_vm._s(itemReply.username))])]),_vm._m(2,true)]),_c('div',{staticClass:"comment-content-reply"},[_c('div',{staticClass:"comment-time"},[_vm._v(" "+_vm._s(_vm.formatTime(itemReply.createTime))+" ")])]),_c('div',{staticClass:"comment-content-box"},[_c('div',{staticClass:"comment-content-text"},[_c('span',[_vm._v(_vm._s(itemReply.content))])])])])])}),0):_vm._e()])}),0):_vm._e(),_c('div',{staticClass:"storm-user-form"},[_c('a-form',{attrs:{"form":_vm.commentForm},on:{"submit":_vm.commentSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'commentContent',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.$getStringObj.getString(
                      _vm.$Strings.Storm_Share_Comment_Input_Is_Null_Tips
                    ),
                  } ],
              } ]),expression:"[\n              'commentContent',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: $getStringObj.getString(\n                      $Strings.Storm_Share_Comment_Input_Is_Null_Tips\n                    ),\n                  },\n                ],\n              },\n            ]"}],ref:"commentInput",attrs:{"placeholder":_vm.$getStringObj.getString(_vm.$Strings.Mind_Mind_Let_Me_Comment_Too)},on:{"change":function($event){return _vm.checkLength($event, 96)}}})],1),_c('a-form-item',{staticClass:"storm-user-form-button"},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.$getStringObj.getString(_vm.$Strings.Storm_Share_Release))+" ")])],1)],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chips-details-single"},[_c('img',{attrs:{"src":require("../../../../static/img/share/fabulous.svg"),"alt":""}}),_c('span')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chips-details-single"},[_c('img',{attrs:{"src":require("../../../../static/img/userData/news_logo.png"),"alt":""}}),_c('span')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"comment-cotnent-detials"},[_c('div',{staticClass:"chips-details-single"},[_c('img',{attrs:{"src":require("../../../../static/img/share/fabulous.svg"),"alt":""}}),_c('span')]),_c('div',{staticClass:"chips-details-single"},[_c('img',{attrs:{"src":require("../../../../static/img/userData/news_logo.png"),"alt":""}}),_c('span')])])}]

export { render, staticRenderFns }